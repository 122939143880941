import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import React, { useState, useCallback, useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import { AppCommonProps } from 'types/app'
import config from '~/config'
import AppContext from '~/context'
import { withI18n } from '~/hocs'
import { assetURL, buildHref } from '~/utils'
import { routes } from '~/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'
import SearchService from '~/services/SearchService'

const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState<any>(undefined)

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true)
    } else {
      setTargetReached(false)
    }
  }, [])
  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    media.addEventListener('change', e => updateTarget(e))

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true)
    } else {
      setTargetReached(false)
    }

    return () => media.removeEventListener('change', e => updateTarget(e))
  }, [])

  return targetReached
}

const Hero = ({ t, banners = [] }: AppCommonProps) => {
  const [topKeyWord, setTopKeyWord] = useState<any>([])
  const router = useRouter()
  if (!banners || banners.length === 0) {
    banners = [
      {
        id: 6920,
        url_redirect: `${config.assetPrefix}/banner/video/6920_1920x1080.webm`,
      },
    ]
  }
  const backupBannerURL = `${config.assetPrefix}/banner/video/6920_1920x1080.webm`
  const context = React.useContext(AppContext)
  const isUnder800 = useMediaQuery(800)
  const isUnder960 = useMediaQuery(960)
  const isUnder1280 = useMediaQuery(1280)

  const imgSrcset = [576, 1200, 1801]
  const srcSet = imgSrcset
    .map(is => assetURL(`banner/banner_1_${is}.webp ${is}w`))
    .join(',')
  useEffect(() => {
    SearchService.getTopKeyWord({limit:5}).then((res) => {
      setTopKeyWord(res.data)
    })
  }, [])

  return (
    <>
      <Head>
        {
          imgSrcset
            .map((is, i) =>
              <link
                key={i}
                rel="preload"
                as="image"
                href={assetURL(`banner/banner_1_${is}.webp`)} />,
            )
        }
        {/* <link
          rel="preload"
          as="image"
          href={assetURL('banner/banner_1_576.webp')}
        />
        <link
          rel="preload"
          as="image"
          href={assetURL('banner/banner_1_1200.webp')}
        />
        <link
          rel="preload"
          as="image"
          href={assetURL('banner/banner_1_1801.webp')}
        /> */}

      </Head>
      <div className="hero">
        <style jsx>
          {`
            .video-banner {
              min-width: 100%;
              min-height: 100%;
            }
            @media (min-width: 900px) {
              .video-banner {
                max-width: 100%;
              }
            }
          `}
        </style>
        <Carousel
          interval={15000}
          slide={false}
          controls={false}
          indicators={false}
          fade={true}
        >
          {banners.map((item: any) => (
            <Carousel.Item key={item.id} onClick={() => {
              if (!isUnder800) {
                router.push(routes.video.as(item.video_id))
              }
            }}>
              <div className="hero--slide" key={item.id}>
                <div className="hero--slide--background">
                  {isUnder800 === undefined || isUnder960 === undefined || isUnder1280 === undefined
                    ? <></>
                    : isUnder960
                      ? <>
                        <video id={`video-sm-${item.id}`} autoPlay={true} muted loop className="video-banner" playsInline>
                          <source src={assetURL(item.url_redirect).replace('1920x1080', '960x540')} type="video/webm" />
                          <source src={assetURL(item.url_redirect).replace('1920x1080', '960x540').replace('webm', 'mp4')} type="video/mp4" />
                          <source src={assetURL(item.url_redirect).replace('1920x1080', '960x540').replace('webm', 'mov')} type="video/mp4" />
                          <source src={backupBannerURL.replace('1920x1080', '960x540').replace('webm', 'mov')} type="video/mp4" />
                        </video>
                      </>
                      : isUnder1280
                        ? <video id={`video-md-${item.id}`} autoPlay={true} muted loop className="video-banner" playsInline>
                          <source src={assetURL(item.url_redirect).replace('1920x1080', '1280x720')} type="video/webm" />
                          <source src={assetURL(item.url_redirect).replace('1920x1080', '1280x720').replace('webm', 'mp4')} type="video/mp4" />
                          <source src={assetURL(item.url_redirect).replace('1920x1080', '1280x720').replace('webm', 'mov')} type="video/mp4" />
                          <source src={backupBannerURL.replace('1920x1080', '960x540').replace('webm', 'mov')} type="video/mp4" />
                        </video>
                        : <video id={`video-lg-${item.id}`} autoPlay={true} muted loop className="video-banner" playsInline>
                          <source src={assetURL(item.url_redirect)} type="video/webm" />
                          <source src={assetURL(item.url_redirect).replace('webm', 'mp4')} type="video/mp4" />
                          <source src={assetURL(item.url_redirect).replace('webm', 'mov')} type="video/mp4" />
                          <source src={backupBannerURL.replace('1920x1080', '960x540').replace('webm', 'mov')} type="video/mp4" />
                        </video>
                  }
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <Carousel.Caption>
          <div className="hero--slide--content">
            <h1 className="hero--slide--title">{t('hero_title')}</h1>
            <h2 className="hero--slide--sub-title">{t('hero_subtitle')}</h2>
            <div className="search">
              <context.SearchPanel />
            </div>
            <div className="d-flex justify-content-center flex-wrap ac-mt-2">
              {topKeyWord.map((item, i) => {
                return <Link key={i} href={buildHref({ pathname: routes.discovery.href, query: { search: `${item?.keyword}`, exclude_ai: true } })}>
                  <a className="top-search" title={`${item?.keyword}`}>
                    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 13 3 C 7.4886661 3 3 7.4886661 3 13 C 3 18.511334 7.4886661 23 13 23 C 15.396652 23 17.59741 22.148942 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148942 17.59741 23 15.396652 23 13 C 23 7.4886661 18.511334 3 13 3 z M 13 5 C 17.430666 5 21 8.5693339 21 13 C 21 17.430666 17.430666 21 13 21 C 8.5693339 21 5 17.430666 5 13 C 5 8.5693339 8.5693339 5 13 5 z" /></svg>
                    <p>{item?.keyword}</p>
                  </a>
                </Link>
              })}
            </div>
          </div>
        </Carousel.Caption>

      </div>
    </>
  )
}
export default withI18n(Hero)
